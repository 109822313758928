import React, { useContext, useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  LinearProgress,
} from '@material-ui/core';
import addPseudoPoleData from '../../utils/createPseudoData';

//Takes headings and rows as arrays
function DataTable(props) {
  const { loading, tableHeadings, tableBodyData } = props;

  const tableBody = () => {
    var cells = [];
    tableBodyData.forEach((pole, rowIndex) => {
      const row = (
        <TableRow
          style={
            rowIndex % 2
              ? { backgroundColor: '#eeeeee' }
              : { backgroundColor: '#fff' }
          }
          key={`tr: ${rowIndex}`}
        >
          {pole.map((dataItem, cellIndex) => {
            return (
              <TableCell key={`tbc: ${cellIndex}`} align="center">
                {dataItem}
              </TableCell>
            );
          })}
        </TableRow>
      );
      cells.push(row);
    });

    return <TableBody>{cells}</TableBody>;
  };

  return (
    <TableContainer component={Paper}>
      {loading ? (
        <LinearProgress />
      ) : (
        <Table size="small" id="sheetTable">
          <TableHead>
            <TableRow>
              {tableHeadings.map((heading, index) => (
                <TableCell key={`th: ${index}`} align="center">
                  {heading}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {tableBody()}
        </Table>
      )}
    </TableContainer>
  );
}

export default DataTable;
