import React, { createContext, useContext, useEffect, useState } from 'react';
import { UserContext } from './UserProvider';
import { firestore } from '../firebase';
import { useParams } from 'react-router';

export const ProjectsContext = createContext();

/**
 * A provider for all things related to projects and sheets, contains a list of
 * projects, the currently chosen project and the currently chosen sheet
 */
function ProjectsProvider({ children }) {
  const user = useContext(UserContext);
  const [projectList, setProjectList] = useState([]);
  const [currentProject, setCurrentProject] = useState({});
  const [currentSheet, setCurrentSheet] = useState();
  const [loadingProjects, setLoadingProjects] = useState(true);

  const getUserProjects = async () => {
    setLoadingProjects(true);
    const projects = [];
    const userClients = user.clients;
    try {
      userClients.forEach(async (clientID) => {
        const clientRef = firestore.collection('clients').doc(clientID);
        const clientDoc = await clientRef.get();
        if (clientDoc.exists) {
          const clientProjects = await clientRef.collection('projects').get();

          clientProjects.forEach((project) => {
            //console.log(project.data());
            projects.push({ clientID: clientID, ...project.data() });
            //console.log('projects:', projects);
          });
        } else {
          //client does not exist
        }
        setProjectList(projects);
      });
    } catch (error) {
      return; //usually happens if projectlist doesn't exist
    }
    setLoadingProjects(false);
  };

  useEffect(() => {
    if (user) {
      getUserProjects();
    }
    return () => {
      setProjectList([]);
    };
  }, [user]);

  return (
    <ProjectsContext.Provider
      value={{
        projectList,
        currentProject,
        currentSheet,
        loadingProjects,
        setCurrentProject,
        setCurrentSheet,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
}

export default ProjectsProvider;
