import React, { useContext, useEffect, useState } from 'react';
import AppBar from '../AppBar.js';
import MenuDrawerFunc from '../components/Menu/MenuDrawerFunc';
import DataTable from '../components/table/DataTable';
import { firestore } from '../firebase';
import { ProjectsContext } from '../providers/ProjectsProvider.jsx';
import { generateWorkbook } from '../utils/exports.js';
import firebase from 'firebase';
import functions from 'firebase';

function SheetPage() {
  const FORM_VERSION = 'v1';

  const projectContext = useContext(ProjectsContext);
  //contains table headings in plain text
  const [tableHeadings, setTableHeadings] = useState([]);
  //contains table rows in plain text
  const [tableBodyData, setTableBodyData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeSheet, setActiveSheet] = useState('');

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  /**
   * Gets the poletype info and sets the type of form used (Borrning, Slagning etc)
   * @returns The headings to be used in the table
   */
  const getForms = async () => {
    const poleType = projectContext.currentSheet.poleType;

    const formsRef = firestore.collection('forms').doc(FORM_VERSION);
    const formsDoc = await formsRef.get();
    const formFields = formsDoc.data()[poleType];
    // console.log('forms done: ', formFields);
    return formFields;
  };

  /* Gets poles from a project in firestore */
  const getPoleData = async () => {
    setLoading(true);
    const sheet = projectContext.currentSheet;
    const currentProject = projectContext.currentProject;
    const poleList = [];

    const poles = await firestore
      .collection('clients')
      .doc(currentProject.clientID)
      .collection('projects')
      .doc(currentProject.id)
      .collection('sheets')
      .doc(sheet.id)
      .collection('poles')
      .orderBy('position')
      .get();

    if (poles.empty) {
      console.log('No pole documents found');
      return [];
    }
    poles.forEach((poleDoc) => {
      poleList.push(poleDoc.data());
    });
    //console.log('poles done');
    return poleList;
  };

  /**
   * Sets up all the data for the table
   * @param {*} poleList
   */
  const setupTableData = async (poleList) => {
    let sectionItems = [];
    const headingData = [];
    const bodyData = [];
    const tags = [];
    const poleTypeInfo = await getForms();

    poleTypeInfo.forEach((section) => {
      sectionItems = section.items;

      sectionItems.forEach((element) => {
        headingData.push(element.title);
        tags.push(element.tag);
      });
    });
    poleList.forEach((pole) => {
      const row = [];
      tags.forEach((tag) => {
        //console.log(tag, ':', pole[tag]);
        if (pole[tag] === undefined) {
          row.push('');
        } else if (pole[tag] === true) {
          row.push('X');
        } else if (pole[tag] === false) {
          row.push('---');
        } else {
          row.push(pole[tag]);
        }
      });
      bodyData.push(row);
    });
    //console.log(bodyData);
    setTableBodyData(bodyData);
    setTableHeadings(headingData);
    //console.log('setup done', bodyData);
    setLoading(false);
  };

  /**
   * Will run everytime projectcontext changes, which is also when a new sheet is
   * selected
   */
  useEffect(() => {
    /* for (let i = 0; i < 15; i++) {
      addPseudoPoleData(
        'testClient',
        'NhoCdcwezET7Hwej3z69',
        'mRzL0d8iKhU2Ccx6Ki1B'
      );
    } */

    const runEffect = async () => {
      if (projectContext.currentSheet !== undefined) {
        const poleList = await getPoleData();
        setupTableData(poleList);
      }
      const activeSheet =
        projectContext.currentSheet != undefined
          ? projectContext.currentSheet.name
          : 'Protokoll';
      setActiveSheet(activeSheet);
    };
    runEffect();
    return () => {};
  }, [projectContext]);

  const handleExport = () => {
    const sayHello = firebase.functions().httpsCallable('sayHello');
    sayHello().then((result) => {
      console.log(result.data);
    });
  };

  return (
    <div id="sheetPage">
      <AppBar
        isExportable={tableHeadings.length === 0 ? false : true}
        activeSheet={activeSheet}
        /* exportFunction={() => generateWorkbook(activeSheet)} */
        exportFunction={handleExport}
        toggleDrawer={toggleDrawer}
      />
      <MenuDrawerFunc open={isDrawerOpen} onClose={toggleDrawer} />

      {/* state change from menudrawer causes re-render to trigger and stops the button animation, will fix later */}
      {projectContext.currentSheet !== undefined && (
        <DataTable
          loading={loading}
          tableHeadings={tableHeadings}
          tableBodyData={tableBodyData}
        />
      )}
    </div>
  );
}

export default SheetPage;
