import React from 'react';
import { Button } from '@material-ui/core';

const AuthButton = ({ title, sign }) => (
  <div>
    <Button color="primary" variant="contained" onClick={sign}>
      {title}
    </Button>
  </div>
);
export default AuthButton;
