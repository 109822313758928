import React, { useContext, useEffect, useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
} from '@material-ui/core';
import { UserContext } from '../../providers/UserProvider';
import { firestore } from '../../firebase';
import { ProjectsContext } from '../../providers/ProjectsProvider';

function MenuDrawerFunc(props) {
  const { open, onClose } = props;
  const projectContext = useContext(ProjectsContext);
  const [sheetList, setSheetList] = useState([]);

  /**
   * Gets all the sheets from the currently chosen project
   * @returns nothing
   */
  const getProjectSheets = async () => {
    let loadedSheets = [];
    const clientID = projectContext.currentProject.clientID;
    const currentProjectID = projectContext.currentProject.id;

    const sheets = await firestore
      .collection('clients')
      .doc(clientID)
      .collection('projects')
      .doc(currentProjectID)
      .collection('sheets')
      .get();
    sheets.forEach((sheet) => {
      loadedSheets.push(sheet.data());
    });
    setSheetList(loadedSheets);
  };

  useEffect(() => {
    const runEffect = async () => {
      getProjectSheets();
    };
    runEffect();
    return () => {};
  }, []);

  const Item = (sheet) => {
    const { name, number, poleType } = sheet.sheet;
    return (
      <ListItem button>
        <ListItemText
          primary={name}
          secondary={`${number} : ${poleType}`}
          onClick={() => {
            projectContext.setCurrentSheet(sheet.sheet);
            console.log('set sheet', sheet.sheet);
          }}
        ></ListItemText>
      </ListItem>
    );
  };

  return (
    <Drawer open={open} onClose={onClose}>
      <List>
        <ListItem>Projekt</ListItem>
        <Divider />
        {!!sheetList &&
          sheetList.map((sheet, index) => {
            return <Item sheet={sheet} key={index} />;
          })}
      </List>
    </Drawer>
  );
}

export default MenuDrawerFunc;
