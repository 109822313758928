import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#879bb7',
      main: '#6983A6',
      dark: '#495B74',
      contrastText: '#fff',
    },
    secondary: {
      light: '#69e2ff',
      main: '#00b0ff',
      dark: '#0081cb',
      contrastText: '#000000',
    },
  },
});

export default theme;
