import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { UserContext } from '../../providers/UserProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

function ProfileAvatar() {
  const classes = useStyles();
  const user = useContext(UserContext);
  return (
    <div className={classes.root}>
      <Avatar
        alt={user ? user.displayName : ''}
        src={user ? user.photoURL : ''}
      />
    </div>
  );
}

export default ProfileAvatar;
