import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import PrivateRoute from './components/route/PrivateRoute';
import AuthPage from './pages/AuthPage';
import ProjectsPage from './pages/ProjectsPage';
import SheetPage from './pages/SheetPage';
import ProjectsProvider from './providers/ProjectsProvider';
import UserProvider from './providers/UserProvider';

const SORT = {
  NAME: { value: 'name', key: 'Namn' },
  NUMBER: { value: 'number', key: 'Nummer' },
  TIMESTAMP: { value: 'timestamp', key: 'Tid' },
  POLETYPE: { value: 'poleType', key: 'Påltyp' },
  POS: { value: 'position', key: 'Position' },
};

class App extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <div className="App">
        <UserProvider>
          <ProjectsProvider>
            <Router>
              <Switch>
                <PrivateRoute path="/projects">
                  <ProjectsPage />
                </PrivateRoute>

                <PrivateRoute path="/sheets">
                  <SheetPage />
                </PrivateRoute>
                <Route path="/">
                  <AuthPage />
                </Route>
              </Switch>
            </Router>
          </ProjectsProvider>
        </UserProvider>
      </div>
    );
  }
}

export default App;
