import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import SignOutButton from './components/auth/SignOutButton';
import ProfileAvatar from './components/profile/ProfileAvatar';
import { UserContext } from './providers/UserProvider';

const styles = {
  title: {
    flexGrow: 1,
    fontSize: 20,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

const ButtonAppBar = (props) => {
  const {
    classes,
    exportFunction,
    toggleDrawer,
    isExportable,
    activeSheet,
  } = props;
  const user = useContext(UserContext);

  return (
    <AppBar position="sticky">
      <Toolbar>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          aria-label="Menu"
          onClick={toggleDrawer}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          className={classes.title}
          type="title"
          align="center"
          color="inherit"
        >
          {activeSheet}
        </Typography>
        {user && <ProfileAvatar />}
        <SignOutButton />

        <Button
          onClick={exportFunction}
          color="primary"
          variant="contained"
          disabled={!isExportable}
        >
          Exportera
        </Button>
      </Toolbar>
    </AppBar>
  );
};

ButtonAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ButtonAppBar);
