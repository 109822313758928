import React, { Component, createContext } from 'react';
import { auth, firestore } from '../firebase';

export const UserContext = createContext();

class UserProvider extends Component {
  state = {
    user: null,
  };

  unsubscribeFromAuth = null;

  render() {
    const { children } = this.props;
    const { user } = this.state;
    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
  }

  componentDidMount = async () => {
    // fetch user and create document
    this.unsubscribeFromAuth = auth.onAuthStateChanged(async (aUser) => {
      if (aUser) {
        const userRef = await this.createUserDocument(aUser);
        userRef.onSnapshot((snap) => {
          this.setState({ user: { uid: snap.id, ...snap.data() } });
        });
      } else {
        this.setState({ user: aUser });
      }
    });
  };

  componentWillUnmount() {
    this.unsubscribeFromAuth = null;
  }

  createUserDocument = async (user, aux) => {
    if (!user) return;
    // ref to user
    const userRef = firestore.doc(`users/${user.uid}`);
    // fetch doc from location
    const snap = await userRef.get();
    // create doc if not exists
    if (!snap.exists) {
      const { displayName, email, photoURL, phoneNumber } = user;
      const createdAt = new Date();
      try {
        await userRef.set({
          displayName,
          email,
          photoURL,
          phoneNumber,
          createdAt,
          ...aux,
        });
      } catch (error) {
        console.error('Error creating user', error);
      }
    }
    return this.getUserDocumentRef(user.uid);
  };

  getUserDocument = async (uid) => {
    if (!uid) return null;

    const userDoc = await firestore.collection('users').doc(uid).get();

    try {
      return { uid, ...userDoc.data() };
    } catch (error) {
      console.error('Error getting user', error);
    }
  };

  getUserDocumentRef = async (uid) => {
    if (!uid) return null;

    try {
      return firestore.collection('users').doc(uid);
    } catch (error) {
      console.error('Error getting user doc', error);
    }
  };
}
export default UserProvider;
