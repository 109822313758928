import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../providers/UserProvider';
import { signInWithGoogle, signOut } from '../../firebase';
import AuthButton from './AuthButton';
import { useHistory } from 'react-router-dom';

const SignOutButton = (props) => {
  return <AuthButton title={'Log out'} sign={signOut} />;
};

export default SignOutButton;
