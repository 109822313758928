import React, { useContext } from 'react';
import Authentication from '../components/auth/Authentication';
import { UserContext } from '../providers/UserProvider';
import { useHistory } from 'react-router-dom';
import { Container, Typography, Button } from '@material-ui/core';
import { auth } from '../firebase';
import { firestore } from '../firebase';
import '../pages/AuthPage.css';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import { Style } from '@material-ui/icons';

const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      customParameters: {
        prompt: 'select_account',
      },
    },
    'microsoft.com',
  ],
};

function AuthPage() {
  const history = useHistory();
  const user = useContext(UserContext);
  if (user) {
    history.push('/projects');
  }

  const signInEmail = () => {
    auth.signInWithEmailAndPassword('testuser@veidekke.com', 'password');
  };

  return (
    <div className="auth-container">
      <Container align="center">
        <Typography variant="h2">Welcome to Metrium!</Typography>

        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
        {/* <Authentication /> */}
        <br />
        <br />
        <Button variant="contained" color="primary" onClick={signInEmail}>
          Log in other test account
        </Button>
      </Container>
      {/* <Link to="/app">APP REDIRECT (for route testing)</Link> */}
    </div>
  );
}

export default AuthPage;
