import {
  Container,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import AuthButton from '../components/auth/AuthButton';
import SignOutButton from '../components/auth/SignOutButton';
import { ProjectsContext } from '../providers/ProjectsProvider';
import { Link, useHistory } from 'react-router-dom';
import '../pages/ProjectPage.css';

function ProjectsPage() {
  const { projectList, setCurrentProject, setCurrentSheet } = useContext(
    ProjectsContext
  );
  const history = useHistory();

  useEffect(() => {
    setCurrentSheet(undefined);
    return () => {};
  }, []);

  const handleClick = (project) => {
    setCurrentProject(project);
    history.push(`/sheets`);
  };

  return (
    <div id="project-list-container">
      <h1>Projects page</h1>
      <h3>Alla projekt som din organisation har</h3>
      <List component="nav">
        {projectList &&
          projectList.map((project, index) => {
            return (
              <ListItem onClick={() => handleClick(project)} button key={index}>
                <ListItemText primary={project.name}></ListItemText>
              </ListItem>
            );
          })}
      </List>
      <SignOutButton />
    </div>
  );
}

export default ProjectsPage;
